#team .profile-tablet {
  display: none;
}

#team .profile-mobile {
  color: black;
}

#team .author-description-container {
  display: none;
}

@media (min-width: 576px) {
  #team .profile-mobile {
    display: none;
  }

  #team .author-description-container {
    display: flex;
  }
}

@media (min-width: 576px) {
  #team .profile-tablet {
    display: inherit;
    color: black;
  }
}
