#contact input[aria-invalid="true"],
#contact textarea[aria-invalid="true"] {
  border-color: #dc3545;
}

#contact input[aria-invalid="true"] + label {
  color: #dc3545;
}

#contact input[type="checkbox"] + label {
  cursor: pointer;
}
