@import "./assets/css/bootstrap.css";
@import "./assets/css/vendors/linericon/style.css";
@import "./assets/css/font-awesome.min.css";
@import "./assets/css/magnific-popup.css";
@import "./assets/css/vendors/owl-carousel/owl.carousel.css";
@import "./assets/css/vendors/lightbox/simpleLightbox.css";
@import "./assets/css/vendors/nice-select/css/nice-select.css";
@import "./assets/css/vendors/animate-css/animate.css";
@import "./assets/css/style.css";

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 570px) {
  .features_area .col-lg-3:nth-child(4) .single_feature {
    margin-bottom: 30px;
  }
}
