/*----------------------------------------------------
@File: Default Styles
@Author: SPONDON IT
Author E-mail: info@spondonit.com

This file contains the styling for the actual theme, this
is the file you need to edit to change the look of the
theme.
---------------------------------------------------- */
/*=====================================================================
@Template Name: Medicare Medical
@Author: Naim LAsker
@Developed By: Naim LAsker


=====================================================================*/
/*----------------------------------------------------*/
/*font Variables*/
/*Color Variables*/
/*=================== fonts ====================*/
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:700|Raleway:400,600");
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #999999;
  font-weight: 400;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999999;
  opacity: 1;
  font-weight: 400;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999999;
  opacity: 1;
  font-weight: 400;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999999;
  font-weight: 400;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999999;
  font-weight: 400;
}

.list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

.row.m0 {
  margin: 0px;
}

body {
  line-height: 24px;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #777777;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
  font-weight: 700;
  color: #222222;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.white_bg {
  background: #fff !important;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
.section_gap {
  padding: 120px 0;
}
@media (max-width: 991px) {
  .section_gap {
    padding: 80px 0;
  }
}

.section_gap_top {
  padding-top: 120px;
}
@media (max-width: 991px) {
  .section_gap_top {
    padding-top: 80px;
  }
}

.section_gap_bottom {
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .section_gap_bottom {
    padding-bottom: 80px;
  }
}

.lite_bg {
  background: #f9f9f9;
}

/* Main Title Area css
============================================================================================ */
.main_title {
  text-align: center;
  margin-bottom: 75px;
}
.main_title h1 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 15px;
}
.main_title p {
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: 300;
}

/* End Main Title Area css
============================================================================================ */
/* Start Gradient Area css
============================================================================================ */
.gradient-bg,
.primary-btn:after,
#search_input_box,
.single_feature:hover,
.about-area .about-inner .single-success:hover,
.service-area,
.categories_post:hover .categories_details,
.blog_right_sidebar .widget_title,
.blog_right_sidebar .search_widget .input-group .form-control,
.blog_right_sidebar .newsletter_widget .bbtns,
.blog_right_sidebar .tag_cloud_widget ul li a:hover,
.blog-pagination .page-item.active .page-link,
.blog-pagination .page-link:hover,
.contact_form .primary-btn,
.single-footer-widget .click-btn {
  background: -webkit-linear-gradient(90deg, #d12e43 0%, #f48464 100%);
  background: -moz-linear-gradient(90deg, #d12e43 0%, #f48464 100%);
  background: -ms-linear-gradient(90deg, #d12e43 0%, #f48464 100%);
  background: -o-linear-gradient(90deg, #d12e43 0%, #f48464 100%);
  background: linear-gradient(90deg, #d12e43 0%, #f48464 100%);
}

.gradient-bg2 {
  background: -webkit-linear-gradient(90deg, #d12e43 0%, #f48464 100%);
  background: -moz-linear-gradient(90deg, #d12e43 0%, #f48464 100%);
  background: -ms-linear-gradient(90deg, #d12e43 0%, #f48464 100%);
  background: -o-linear-gradient(90deg, #d12e43 0%, #f48464 100%);
  background: linear-gradient(90deg, #d12e43 0%, #f48464 100%);
}

.gradient-color,
.single-footer-widget .bb-btn,
.copyright p a {
  background: -webkit-linear-gradient(90deg, #d12e43 0%, #f48464 100%);
  background: -moz-linear-gradient(90deg, #d12e43 0%, #f48464 100%);
  background: -ms-linear-gradient(90deg, #d12e43 0%, #f48464 100%);
  background: -o-linear-gradient(90deg, #d12e43 0%, #f48464 100%);
  background: linear-gradient(90deg, #d12e43 0%, #f48464 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* End Gradient Area css
============================================================================================ */
/* Start Buttons Area css
============================================================================================ */
.primary-btn {
  position: relative;
  display: inline-block;
  padding: 0px 38px;
  color: #222222;
  background: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 50px;
  border-radius: 5px;
  outline: none !important;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 1;
}
.primary-btn:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  content: "";
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.primary-btn:hover {
  color: #fff;
}
.primary-btn:hover:after {
  opacity: 1;
  visibility: visible;
}

/* End Buttons Area css
============================================================================================ */
/* Start Overlay css
============================================================================================ */
.overlay {
  position: relative;
}

.overlay:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  z-index: -1;
}

/* End Overlay css
============================================================================================ */
/* Start Video Play css
============================================================================================ */
.video-play-button {
  position: relative;
  box-sizing: content-box;
  display: inline-block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  padding: 46px 8px 18px 28px;
}
.video-play-button:before,
.video-play-button:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  width: 60px;
  height: 60px;
  background: -webkit-linear-gradient(90deg, #e93d88 0%, #e5935a 100%);
  background: -moz-linear-gradient(90deg, #e93d88 0%, #e5935a 100%);
  background: -ms-linear-gradient(90deg, #e93d88 0%, #e5935a 100%);
  background: -o-linear-gradient(90deg, #e93d88 0%, #e5935a 100%);
  background: linear-gradient(90deg, #e93d88 0%, #e5935a 100%);
  border-radius: 50%;
}
.video-play-button:before {
  z-index: 0;
  -webkit-animation: pulse-border 2000ms ease-out infinite;
  -moz-animation: pulse-border 2000ms ease-out infinite;
  -o-animation: pulse-border 2000ms ease-out infinite;
  animation: pulse-border 2000ms ease-out infinite;
}
.video-play-button:after {
  z-index: 1;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 16px solid #ffffff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
.video-play-button:hover:after {
  background-color: #ea1162;
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1);
    transform: translate(-50%, -50%) translateZ(0) scale(1);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    opacity: 0.1;
  }
}
@-moz-keyframes pulse-border {
  0% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1);
    transform: translate(-50%, -50%) translateZ(0) scale(1);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    opacity: 0.1;
  }
}
@-o-keyframes pulse-border {
  0% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1);
    transform: translate(-50%, -50%) translateZ(0) scale(1);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    opacity: 0.1;
  }
}
@keyframes pulse-border {
  0% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1);
    transform: translate(-50%, -50%) translateZ(0) scale(1);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    opacity: 0.1;
  }
}
/* End Video Play css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
.header_area {
  position: relative;
  width: 100%;
  z-index: 999999;
  transition: background 0.4s, all 0.3s linear;
}
.header_area .navbar {
  background: transparent;
  padding: 0px;
  border: 0px;
  border-radius: 0px;
}
.header_area .navbar .nav .nav-item {
  margin-right: 45px;
}
.header_area .navbar .nav .nav-item .nav-link {
  font: 600 12px/80px "Raleway", sans-serif;
  text-transform: uppercase;
  color: #222222;
  padding: 0px;
  display: inline-block;
}
.header_area .navbar .nav .nav-item .nav-link:after {
  display: none;
}
@media (max-width: 991px) {
  .header_area .navbar .nav .nav-item .nav-link {
    color: #ffffff;
  }
}
.header_area .navbar .nav .nav-item:hover .nav-link,
.header_area .navbar .nav .nav-item.active .nav-link {
  color: #d12e43;
}
.header_area .navbar .nav .nav-item.submenu {
  position: relative;
}
.header_area .navbar .nav .nav-item.submenu ul {
  border: none;
  padding: 0px;
  border-radius: 0px;
  box-shadow: none;
  margin: 0px;
  background: #fff;
}
@media (min-width: 992px) {
  .header_area .navbar .nav .nav-item.submenu ul {
    position: absolute;
    top: 120%;
    left: 0px;
    min-width: 200px;
    text-align: left;
    opacity: 0;
    transition: all 300ms ease-in;
    visibility: hidden;
    display: block;
    border: none;
    padding: 0px;
    border-radius: 0px;
  }
}
.header_area .navbar .nav .nav-item.submenu ul:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #eeeeee transparent transparent transparent;
  position: absolute;
  right: 24px;
  top: 45px;
  z-index: 3;
  opacity: 0;
  transition: all 400ms linear;
}
.header_area .navbar .nav .nav-item.submenu ul .nav-item {
  display: block;
  float: none;
  margin-right: 0px;
  border-bottom: 1px solid #ededed;
  margin-left: 0px;
  transition: all 0.4s linear;
}
.header_area .navbar .nav .nav-item.submenu ul .nav-item.active {
  background: #d12e43;
}
.header_area .navbar .nav .nav-item.submenu ul .nav-item.active .nav-link {
  color: #fff;
}
.header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
  line-height: 45px;
  color: #222222;
  padding: 0px 30px;
  transition: all 150ms linear;
  display: block;
  margin-right: 0px;
}
.header_area .navbar .nav .nav-item.submenu ul .nav-item:last-child {
  border-bottom: none;
}
.header_area .navbar .nav .nav-item.submenu ul .nav-item:hover .nav-link {
  background: #d12e43;
  color: #fff;
}
@media (min-width: 992px) {
  .header_area .navbar .nav .nav-item.submenu:hover ul {
    visibility: visible;
    opacity: 1;
    top: 100%;
  }
}
.header_area .navbar .nav .nav-item.submenu:hover ul .nav-item {
  margin-top: 0px;
}
.header_area .navbar .nav .nav-item:last-child {
  margin-right: 0px;
}
.header_area .navbar .social-icons a {
  color: #ddd;
  padding-right: 15px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.header_area .navbar .social-icons a:hover {
  color: #d12e43;
}
.header_area .navbar .search {
  font-size: 12px;
  line-height: 60px;
  display: inline-block;
  color: #222222;
  padding-left: 30px;
}
@media (max-width: 991px) {
  .header_area .navbar .search {
    color: #ffffff;
  }
}
.header_area .navbar .search i {
  font-weight: 600;
}
.header_area.navbar_fixed .main_menu {
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  background: #ffffff;
  -webkit-transform: translateY(70px);
  -moz-transform: translateY(70px);
  -ms-transform: translateY(70px);
  -o-transform: translateY(70px);
  transform: translateY(70px);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
}
.header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
  line-height: 70px;
}

#search_input_box {
  text-align: center;
  padding: 5px 20px;
}
#search_input_box .form-control {
  background: transparent;
  border: 0;
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
  padding: 0;
}
#search_input_box .form-control:focus {
  box-shadow: none;
  outline: none;
}
#search_input_box input.placeholder {
  color: #ffffff;
  font-size: 14px;
}
#search_input_box input:-moz-placeholder {
  color: #ffffff;
  font-size: 14px;
}
#search_input_box input::-moz-placeholder {
  color: #ffffff;
  font-size: 14px;
}
#search_input_box input::-webkit-input-placeholder {
  color: #ffffff;
  font-size: 14px;
}
#search_input_box .btn {
  width: 0;
  height: 0;
  padding: 0;
  border: 0;
}
#search_input_box .lnr-cross {
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  padding: 10px 3px;
}

.header-top {
  background: -webkit-linear-gradient(90deg, #772dff 0%, #9e4fff 100%);
  background: -moz-linear-gradient(90deg, #772dff 0%, #9e4fff 100%);
  background: -ms-linear-gradient(90deg, #772dff 0%, #9e4fff 100%);
  background: -o-linear-gradient(90deg, #772dff 0%, #9e4fff 100%);
  background: linear-gradient(90deg, #772dff 0%, #9e4fff 100%);
  font-size: 12px;
}
@media (max-width: 991px) {
  .header-top {
    display: none;
  }
}
.header-top a {
  color: #fff;
  text-transform: uppercase;
}
.header-top ul li {
  display: inline-block;
  margin-left: 15px;
}
.header-top .nice-select {
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  padding-left: 0;
}
.header-top .nice-select:after {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}
.header-top .nice-select .current {
  margin-right: 0;
}
.header-top .nice-select .list {
  width: 100%;
}
.header-top .nice-select .list .option {
  display: block;
  margin: 0;
  color: #000000;
  padding-left: 10px;
}
.header-top .header-top-right {
  text-align: right;
}
.header-top .header-top-right a {
  margin-right: 40px;
}
.header-top .header-top-right a:last-child {
  margin-right: 0;
}
.header-top .header-top-right a .lnr {
  color: #fff;
  font-weight: 700;
  margin-right: 8px;
}
@media (max-width: 767px) {
  .header-top .header-top-right .text {
    display: none;
  }
}
.header-top .primary-btn {
  border-radius: 0px;
}

@media (max-width: 991px) {
  /* Main Menu Area css
  ============================================================================================ */
  .navbar-toggler {
    border: none;
    border-radius: 0px;
    padding: 0px;
    cursor: pointer;
    margin-top: 27px;
    margin-bottom: 23px;
  }

  .header_area {
    position: absolute;
  }

  .header_area .navbar {
    background: #d12e43;
  }
  .header_area .navbar .navbar-nav .social-icons {
    margin-top: 20px;
  }

  .navbar-toggler[aria-expanded="false"] span:nth-child(2) {
    opacity: 1;
  }

  .navbar-toggler[aria-expanded="true"] span:nth-child(2) {
    opacity: 0;
  }

  .navbar-toggler[aria-expanded="true"] span:first-child {
    transform: rotate(-45deg);
    position: relative;
    top: 7.5px;
  }

  .navbar-toggler[aria-expanded="true"] span:last-child {
    transform: rotate(45deg);
    bottom: 6px;
    position: relative;
  }

  .navbar-toggler span {
    display: block;
    width: 25px;
    height: 3px;
    background: #fff;
    margin: auto;
    margin-bottom: 4px;
    transition: all 400ms linear;
    cursor: pointer;
  }

  .navbar .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .nav {
    padding: 0px 0px;
  }

  .header_area + section,
  .header_area + row,
  .header_area + div {
    margin-top: 117px;
  }

  .header_top .nav {
    padding: 0px;
  }

  .header_area .navbar .nav .nav-item .nav-link {
    line-height: 40px;
    margin-right: 0px;
    display: block;
    border-bottom: 1px solid #ededed33;
    border-radius: 0px;
  }

  .header_area .navbar .search {
    margin-left: 0px;
  }

  .header_area .navbar-collapse {
    max-height: 340px;
    overflow-y: scroll;
  }

  .header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
    padding: 0px 15px;
  }

  .header_area .navbar .nav .nav-item {
    margin-right: 0px;
  }
  .header_area .navbar .nav .nav-item.active .nav-link,
  .header_area .navbar .nav .nav-item:hover .nav-link {
    color: #000;
  }

  .header_area + section,
  .header_area + row,
  .header_area + div {
    margin-top: 0;
  }

  .header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
    line-height: 40px;
  }

  /* End Main Menu Area css*/
}
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Home Banner Area css
============================================================================================ */
.home_banner_area {
  z-index: 1;
  background: url(../images/ahmed-zayan-unsplash.jpg) no-repeat scroll center
    center;
  /*background: url(../images/banner/home-banner.jpg) no-repeat scroll center
    center;*/
  background-size: cover;
}
.home_banner_area.overlay:after {
  background: #000;
  opacity: 0.2;
}
.home_banner_area .banner_inner .banner_content {
  color: #fff;
  text-align: center;
}
@media (max-width: 991px) {
  .home_banner_area .banner_inner .banner_content {
    margin-top: 50px;
  }
}
.home_banner_area .banner_inner .banner_content h2 {
  margin-top: 0px;
  font-size: 48px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  line-height: 54px;
  color: #fff;
}
@media (max-width: 570px) {
  .home_banner_area .banner_inner .banner_content h2 {
    font-size: 36px;
  }
}
.home_banner_area .banner_inner .banner_content p {
  max-width: 680px;
  font-size: 15px;
  margin: 25px 0px 45px;
}
@media (max-width: 991px) {
  .home_banner_area .banner_inner .banner_content p {
    max-width: 460px;
  }
}

.blog_banner {
  min-height: 780px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 0px;
}
.blog_banner .banner_inner {
  background: #04091e;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 780px;
  z-index: 1;
}
.blog_banner .banner_inner .overlay {
  background: url(../images/banner/banner-2.jpg) no-repeat scroll center center;
  opacity: 0.5;
  height: 125%;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: -1;
}
.blog_banner .banner_inner .blog_b_text {
  max-width: 700px;
  margin: auto;
  color: #fff;
  margin-top: 40px;
}
.blog_banner .banner_inner .blog_b_text h2 {
  font-size: 60px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  line-height: 66px;
  margin-bottom: 15px;
}
.blog_banner .banner_inner .blog_b_text p {
  font-size: 16px;
  margin-bottom: 35px;
}
.blog_banner .banner_inner .blog_b_text .white_bg_btn {
  line-height: 42px;
  padding: 0px 45px;
}

/* common Banner Area css
============================================================================================ */
.banner_area {
  position: relative;
  z-index: 1;
  min-height: 300px;
}
.banner_area .banner_inner {
  position: relative;
  background: url(../images/banner/common-banner.jpg) no-repeat center center;
  background-size: cover;
  width: 100%;
  min-height: 300px;
  z-index: 1;
}
@media (max-width: 991px) {
  .banner_area .banner_inner {
    padding-top: 70px;
  }
}
.banner_area .banner_inner.overlay:after {
  background: #000;
  opacity: 0.3;
}
.banner_area .banner_inner .banner_content h2 {
  color: #fff;
  font-size: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}
.banner_area .banner_inner .banner_content .page_link a {
  font-size: 14px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  margin-right: 30px;
  position: relative;
  text-transform: capitalize;
}
.banner_area .banner_inner .banner_content .page_link a:before {
  content: "\f178";
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
}
.banner_area .banner_inner .banner_content .page_link a:last-child {
  margin-right: 0px;
}
.banner_area .banner_inner .banner_content .page_link a:last-child:before {
  display: none;
}

/* End Home Banner Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Start Features Area css
============================================================================================ */
.features_area {
  position: relative;
  margin-top: -75px;
  z-index: 222;
}
.features_area .col-lg-3:nth-child(3) .single_feature,
.features_area .col-lg-3:nth-child(4) .single_feature {
  margin-bottom: 0;
}
@media (max-width: 570px) {
  .features_area .col-lg-3:nth-child(3) .single_feature {
    margin-bottom: 30px;
  }
}
@media (max-width: 991px) {
  .features_area {
    margin-top: 80px;
  }
}
.features_area .counter_wrapper {
  background: #f4f8ff;
  margin-left: 0;
  margin-right: 0;
  padding: 30px 15px;
}

.single_feature {
  background: #fff;
  padding: 18px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media (max-width: 991px) {
  .single_feature {
    margin-bottom: 30px;
  }
}
.single_feature .thumb {
  display: inline-block;
}
.single_feature .thumb img {
  margin-top: -55px;
}
.single_feature .info-content {
  display: inline-block;
  padding-left: 15px;
}
.single_feature .info-content h4 {
  font-size: 20px;
  color: #222222;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-transform: uppercase;
}
.single_feature .info-content p {
  color: #999999;
  margin: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single_feature:hover {
  color: #fff;
}
.single_feature:hover img {
  filter: brightness(0) invert(1);
  -o-filter: brightness(0) invert(1);
  -ms-filter: brightness(0) invert(1);
  -moz-filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single_feature:hover h4 {
  color: #fff;
}
.single_feature:hover p {
  color: #fff;
}

#about .features_area {
  margin-top: 120px;
}
@media (max-width: 991px) {
  #about .features_area {
    margin-top: 80px;
  }
}

/* End Features Area css
============================================================================================ */
/* Start video Area css
============================================================================================ */
@media (max-width: 768px) {
  .video-sec-area {
    padding-top: 0px;
  }
}

.video-left {
  /*background: url(../images/video-bg.jpg) no-repeat center;*/
  background: url(../images/garth-manthe-unsplash.jpg) no-repeat center;
  background-size: cover;
  height: 350px;
  border-radius: 10px;
  z-index: 1;
}
.video-left.overlay {
  border-radius: 10px;
}
.video-left.overlay:after {
  background: #000;
  opacity: 0.3;
  border-radius: 10px;
}
.video-left .video-inner {
  height: 100%;
}
.video-left .play-btn {
  z-index: 2;
}
@media (max-width: 800px) {
  .video-left {
    margin-top: 100px;
  }
}

.video-right {
  margin-top: 70px;
  padding-left: 30px;
}
.video-right h6 {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #d12e43;
  font-size: 12px;
  font-weight: 300;
}
.video-right p span {
  color: #000;
  font-weight: 400;
}
@media (max-width: 960px) {
  .video-right {
    margin-top: 50px;
  }
}
.video-right .primary-btn {
  background: #f4f8ff;
}
.video-right .right-bottom-area {
  margin-top: 18%;
  background: #f4f8ff;
  padding: 40px;
  border-radius: 5px;
}
@media (max-width: 991px) {
  .video-right .right-bottom-area {
    margin-top: 10%;
  }
}
.video-right .right-bottom-area .author-title {
  margin-top: 30px;
}
.video-right .right-bottom-area .author-title .thumb {
  display: inline-block;
}
.video-right .right-bottom-area .author-title .thumb img {
  margin-top: -25px;
}
.video-right .right-bottom-area .author-title .a-desc {
  display: inline-block;
  padding-left: 15px;
}
.video-right .right-bottom-area .author-title .a-desc h6 {
  margin-bottom: 5px;
  color: #222222;
}

.about-area {
  margin-top: -25.5%;
}
@media (max-width: 1920px) {
  .about-area {
    margin-top: -17.7%;
  }
}
@media (max-width: 1680px) {
  .about-area {
    margin-top: -20.3%;
  }
}
@media (max-width: 1500px) {
  .about-area {
    margin-top: -22.7%;
  }
}
@media (max-width: 1440px) {
  .about-area {
    margin-top: -23.6%;
  }
}
@media (max-width: 1380px) {
  .about-area {
    margin-top: -24.6%;
  }
}
@media (max-width: 1200px) {
  .about-area {
    margin-top: -28.5%;
  }
}
@media (max-width: 1024px) {
  .about-area {
    margin-top: -40.6%;
  }
}
@media (max-width: 991px) {
  .about-area {
    margin-top: 0;
  }
}
.about-area .about-inner .row {
  margin-left: -30px;
  margin-right: -30px;
}
@media (max-width: 767px) {
  .about-area .about-inner .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.about-area .about-inner .single-success {
  background: #f4f8ff;
  padding: 40px 30px;
  margin-top: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 5px;
}
.about-area .about-inner .single-success h5 {
  font-size: 18px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.about-area .about-inner .single-success p {
  margin: 0;
}
.about-area .about-inner .single-success:hover {
  box-shadow: 0 10px 30px rgba(39, 0, 110, 0.3);
  color: #fff;
}
.about-area .about-inner .single-success:hover h5 {
  color: #fff;
}

/* End video Area css
============================================================================================ */
/* Start service Area css
============================================================================================ */
.service-area {
  margin: 120px 0;
}
.service-area .col-lg-4.col-md-6:last-child .single-service {
  margin-bottom: 0;
}
.service-area .main_title {
  color: #fff;
}
.service-area .main_title h1 {
  color: #fff;
}

.single-service {
  color: #fff;
  margin-bottom: 30px;
}
.single-service .service-thumb {
  position: relative;
  overflow: hidden;
}
@media (max-width: 767px) {
  .single-service .service-thumb img {
    width: 100%;
  }
}
.single-service .service-thumb:before {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.4);
  z-index: 999;
  left: 110%;
  top: -15px;
  height: 100%;
  width: 110%;
  -webkit-transform: rotate(55deg);
  -moz-transform: rotate(55deg);
  -ms-transform: rotate(55deg);
  -o-transform: rotate(55deg);
  transform: rotate(55deg);
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.single-service h5 {
  font-size: 18px;
  margin: 20px 0 15px;
}
.single-service h5 a {
  color: #fff;
}
.single-service:hover .service-thumb:before {
  left: -110%;
  top: 15px;
}

.service-area-2 .service-2-left .get-know {
  margin-bottom: 70px;
}
@media (max-width: 991px) {
  .service-area-2 .service-2-left .get-know {
    margin-bottom: 50px;
  }
}
.service-area-2 .service-2-left .get-know .df-color {
  color: #d12e43;
}
.service-area-2 .service-2-left .get-know h1 {
  font-size: 36px;
  margin-bottom: 20px;
}
.service-area-2 .service-2-left .author-title {
  margin-top: 30px;
}
.service-area-2 .service-2-left .author-title .thumb {
  display: inline-block;
}
.service-area-2 .service-2-left .author-title .thumb img {
  margin-top: -25px;
}
.service-area-2 .service-2-left .author-title .a-desc {
  display: inline-block;
  padding-left: 15px;
}
.service-area-2 .service-2-left .author-title .a-desc h6 {
  margin-bottom: 5px;
  color: #222222;
}
.service-area-2 .service-2-right .s-img {
  position: relative;
  overflow: hidden;
}
.service-area-2 .service-2-right .s-img:before {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.4);
  z-index: 999;
  left: 110%;
  top: -15px;
  height: 100%;
  width: 110%;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.service-area-2 .service-2-right .s-img:hover:before {
  left: -110%;
  top: 0;
}
.service-area-2 .service-2-right .left-image {
  margin-top: 60px;
}
.service-area-2 .service-2-right .left-image img {
  margin-bottom: 30px;
}
@media (max-width: 570px) {
  .service-area-2 .service-2-right .right-image {
    margin-top: 10px;
  }
}
.service-area-2 .service-2-right img {
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .service-area-2 .service-2-right img {
    margin: 30px;
  }
}
@media (max-width: 767px) {
  .service-area-2 .service-2-right img {
    margin: 10px;
  }
}

#service .service-area {
  margin: 0;
}

#service .service-area-2 {
  padding-bottom: 90px;
}
@media (max-width: 991px) {
  #service .service-area-2 {
    padding-bottom: 50px;
  }
}

/* End service Area css
============================================================================================ */
/* Start Team Area css
============================================================================================ */
.team-area {
  padding-top: 90px;
}

.single_member {
  text-align: center;
  box-shadow: none;
}
@media (max-width: 991px) {
  .single_member {
    margin-bottom: 30px;
  }
}
.single_member .author_decs {
  display: inline-block;
  padding: 25px 15px;
  position: absolute;
  left: 50%;
  bottom: -23%;
  background: #fff;
  transform: translateX(-50%);
  width: 65%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 991px) {
  .single_member .author_decs {
    padding: 25px 5px;
  }
}
.single_member .author_decs h4 {
  margin: 20px 0px 0px;
}
.single_member .author_decs p {
  margin: 0px;
}
.single_member:hover .author_decs {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}
@media (max-width: 991px) {
  .single_member:hover .author_decs {
    bottom: 30px;
  }
}

#team .team-area {
  padding-bottom: 90px;
}
#team .single_member {
  margin-bottom: 30px;
}
#team .single_member:hover .author_decs {
  bottom: 30px;
}

/* End Team Area css
============================================================================================ */
/* Start CTA Area css
============================================================================================ */
.cta-area {
  /*background: url(../images/cta-bg.jpg) center no-repeat;*/
  background: url(../images/chris-barbalis-unsplash.jpg) center no-repeat;
  background-size: cover;
  text-align: center;
  color: #fff;
  z-index: 1;
}
.cta-area.overlay:after {
  background: #000;
  opacity: 0.5;
}
.cta-area h1 {
  font-size: 36px;
  color: #fff;
}
.cta-area .primary-btn {
  margin-top: 15px;
}

/* End CTA Area css
============================================================================================ */
/* Start Blog Area css
============================================================================================ */
@media (max-width: 991px) {
  .blog_area {
    padding-bottom: 50px;
  }
}

.single-blog {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media (max-width: 991px) {
  .single-blog {
    margin-bottom: 30px;
  }
}
@media (max-width: 570px) {
  .single-blog {
    margin: 0 0 30px 0;
  }
}
.single-blog .blog-thumb {
  position: relative;
  overflow: hidden;
}
.single-blog .blog-thumb:before {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.4);
  z-index: 999;
  left: 110%;
  top: -15px;
  height: 100%;
  width: 130%;
  -webkit-transform: rotate(55deg);
  -moz-transform: rotate(55deg);
  -ms-transform: rotate(55deg);
  -o-transform: rotate(55deg);
  transform: rotate(55deg);
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.single-blog .blog-details {
  padding: 25px;
}
.single-blog .blog-details .blog-meta span {
  padding-right: 15px;
  text-transform: uppercase;
}
.single-blog .blog-details .blog-meta span i.fa {
  padding-right: 10px;
}
.single-blog .blog-details h5 {
  margin: 15px 0px 15px;
}
.single-blog .blog-details h5 a {
  color: #222222;
  font-size: 18px;
  line-height: 27px;
}
.single-blog:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.single-blog:hover .blog-thumb:hover:before {
  left: -130%;
  top: 10px;
}

/* End Blog Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*================= latest_blog_area css =============*/
.latest_blog_area {
  background: #f9f9ff;
}

.single-recent-blog-post {
  margin-bottom: 30px;
}
.single-recent-blog-post .thumb {
  overflow: hidden;
}
.single-recent-blog-post .thumb img {
  transition: all 0.7s linear;
}
.single-recent-blog-post .details {
  padding-top: 30px;
}
.single-recent-blog-post .details .sec_h4 {
  line-height: 24px;
  padding: 10px 0px 13px;
  transition: all 0.3s linear;
}
.single-recent-blog-post .details .sec_h4:hover {
  color: #777777;
}
.single-recent-blog-post .date {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.single-recent-blog-post:hover img {
  transform: scale(1.23) rotate(10deg);
}

.tags .tag_btn {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid #eeeeee;
  display: inline-block;
  padding: 1px 18px;
  text-align: center;
  color: #222222;
}
.tags .tag_btn:before {
  background: #d12e43;
}
.tags .tag_btn + .tag_btn {
  margin-left: 2px;
}

/*========= blog_categorie_area css ===========*/
.blog_categorie_area {
  padding-top: 80px;
  padding-bottom: 80px;
}

.categories_post {
  position: relative;
  text-align: center;
  cursor: pointer;
}
.categories_post img {
  max-width: 100%;
}
.categories_post .categories_details {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: rgba(34, 34, 34, 0.8);
  color: #fff;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}
.categories_post .categories_details h5 {
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: #fff;
  position: relative;
}
.categories_post .categories_details p {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0px;
}
.categories_post .categories_details .border_line {
  margin: 10px 0px;
  background: #fff;
  width: 100%;
  height: 1px;
}
.categories_post:hover .categories_details {
  background-color: transparent;
  opacity: 0.5;
}

/*============ blog_left_sidebar css ==============*/
.blog_item {
  margin-bottom: 40px;
}

.blog_info {
  padding-top: 30px;
}
.blog_info .post_tag {
  padding-bottom: 20px;
}
.blog_info .post_tag a {
  font: 300 14px/21px "Raleway", sans-serif;
  color: #222222;
}
.blog_info .post_tag a:hover {
  color: #777777;
}
.blog_info .post_tag a.active {
  color: #d12e43;
}
.blog_info .blog_meta li a {
  font: 300 14px/20px "Raleway", sans-serif;
  color: #777777;
  vertical-align: middle;
  padding-bottom: 12px;
  display: inline-block;
}
.blog_info .blog_meta li a i {
  color: #222222;
  font-size: 16px;
  font-weight: 600;
  padding-left: 15px;
  line-height: 20px;
  vertical-align: middle;
}
.blog_info .blog_meta li a:hover {
  color: #d12e43;
}

.blog_post img {
  max-width: 100%;
}

.blog_details {
  padding-top: 20px;
}
.blog_details h2 {
  font-size: 24px;
  line-height: 36px;
  color: #222222;
  font-weight: 600;
  transition: all 0.3s linear;
}
.blog_details h2:hover {
  color: #d12e43;
}
.blog_details p {
  margin-bottom: 26px;
}

.view_btn {
  font-size: 14px;
  line-height: 36px;
  display: inline-block;
  color: #222222;
  font-weight: 500;
  padding: 0px 30px;
  background: #fff;
}

.blog_right_sidebar {
  border: 1px solid #eeeeee;
  padding: 30px;
}
.blog_right_sidebar .widget_title {
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #fff;
  padding: 8px 0px;
  margin-bottom: 30px;
}
.blog_right_sidebar .search_widget .input-group .form-control {
  font-size: 14px;
  line-height: 29px;
  border: 0px;
  width: 100%;
  font-weight: 300;
  color: #fff;
  padding-left: 20px;
  border-radius: 45px;
  z-index: 0;
}
.blog_right_sidebar .search_widget .input-group .form-control.placeholder {
  color: #fff;
}
.blog_right_sidebar .search_widget .input-group .form-control:-moz-placeholder {
  color: #fff;
}
.blog_right_sidebar
  .search_widget
  .input-group
  .form-control::-moz-placeholder {
  color: #fff;
}
.blog_right_sidebar
  .search_widget
  .input-group
  .form-control::-webkit-input-placeholder {
  color: #fff;
}
.blog_right_sidebar .search_widget .input-group .form-control:focus {
  box-shadow: none;
}
.blog_right_sidebar .search_widget .input-group .btn-default {
  position: absolute;
  right: 20px;
  background: transparent;
  border: 0px;
  box-shadow: none;
  font-size: 14px;
  color: #fff;
  padding: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.blog_right_sidebar .author_widget {
  text-align: center;
}
.blog_right_sidebar .author_widget h4 {
  font-size: 18px;
  line-height: 20px;
  color: #222222;
  margin-bottom: 5px;
  margin-top: 30px;
}
.blog_right_sidebar .author_widget p {
  margin-bottom: 0px;
}
.blog_right_sidebar .author_widget .social_icon {
  padding: 7px 0px 15px;
}
.blog_right_sidebar .author_widget .social_icon a {
  font-size: 14px;
  color: #222222;
  transition: all 0.2s linear;
}
.blog_right_sidebar .author_widget .social_icon a + a {
  margin-left: 20px;
}
.blog_right_sidebar .author_widget .social_icon a:hover {
  color: #d12e43;
}
.blog_right_sidebar .popular_post_widget .post_item .media-body {
  justify-content: center;
  align-self: center;
  padding-left: 20px;
}
.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  margin-bottom: 4px;
  transition: all 0.3s linear;
}
.blog_right_sidebar .popular_post_widget .post_item .media-body h3:hover {
  color: #d12e43;
}
.blog_right_sidebar .popular_post_widget .post_item .media-body p {
  font-size: 12px;
  line-height: 21px;
  margin-bottom: 0px;
}
.blog_right_sidebar .popular_post_widget .post_item + .post_item {
  margin-top: 20px;
}
.blog_right_sidebar .post_category_widget .cat-list li {
  border-bottom: 2px dotted #eee;
  transition: all 0.3s ease 0s;
  padding-bottom: 12px;
}
.blog_right_sidebar .post_category_widget .cat-list li a {
  font-size: 14px;
  line-height: 20px;
  color: #777;
}
.blog_right_sidebar .post_category_widget .cat-list li a p {
  margin-bottom: 0px;
}
.blog_right_sidebar .post_category_widget .cat-list li + li {
  padding-top: 15px;
}
.blog_right_sidebar .post_category_widget .cat-list li:hover {
  border-color: #d12e43;
}
.blog_right_sidebar .post_category_widget .cat-list li:hover a {
  color: #d12e43;
}
.blog_right_sidebar .newsletter_widget {
  text-align: center;
}
.blog_right_sidebar .newsletter_widget .form-group {
  margin-bottom: 8px;
}
.blog_right_sidebar .newsletter_widget .input-group-prepend {
  margin-right: -1px;
}
.blog_right_sidebar .newsletter_widget .input-group-text {
  background: #fff;
  border-radius: 0px;
  vertical-align: top;
  font-size: 12px;
  line-height: 36px;
  padding: 0px 0px 0px 15px;
  border: 1px solid #eeeeee;
  border-right: 0px;
}
.blog_right_sidebar .newsletter_widget .form-control {
  font-size: 12px;
  line-height: 24px;
  color: #cccccc;
  border: 1px solid #eeeeee;
  border-left: 0px;
  border-radius: 0px;
}
.blog_right_sidebar .newsletter_widget .form-control.placeholder {
  color: #cccccc;
}
.blog_right_sidebar .newsletter_widget .form-control:-moz-placeholder {
  color: #cccccc;
}
.blog_right_sidebar .newsletter_widget .form-control::-moz-placeholder {
  color: #cccccc;
}
.blog_right_sidebar
  .newsletter_widget
  .form-control::-webkit-input-placeholder {
  color: #cccccc;
}
.blog_right_sidebar .newsletter_widget .form-control:focus {
  outline: none;
  box-shadow: none;
}
.blog_right_sidebar .newsletter_widget .bbtns {
  color: #fff;
  font-size: 12px;
  line-height: 38px;
  display: inline-block;
  font-weight: 500;
  padding: 0px 24px 0px 24px;
  border-radius: 0;
}
.blog_right_sidebar .newsletter_widget .text-bottom {
  font-size: 12px;
}
.blog_right_sidebar .tag_cloud_widget ul li {
  display: inline-block;
}
.blog_right_sidebar .tag_cloud_widget ul li a {
  display: inline-block;
  border: 1px solid #eee;
  background: #fff;
  padding: 0px 13px;
  margin-bottom: 8px;
  transition: all 0.3s ease 0s;
  color: #222222;
  font-size: 12px;
}
.blog_right_sidebar .tag_cloud_widget ul li a:hover {
  color: #fff;
}
.blog_right_sidebar .br {
  width: 100%;
  height: 1px;
  background: #eeeeee;
  margin: 30px 0px;
}

.blog-pagination {
  padding-top: 25px;
  padding-bottom: 95px;
}
.blog-pagination .page-link {
  border-radius: 0;
}
.blog-pagination .page-item {
  border: none;
}

.page-link {
  background: transparent;
  font-weight: 400;
}

.blog-pagination .page-item.active .page-link {
  border-color: transparent;
  color: #fff;
}

.blog-pagination .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #8a8a8a;
  border: none;
}

.blog-pagination .page-link .lnr {
  font-weight: 600;
}

.blog-pagination .page-item:last-child .page-link,
.blog-pagination .page-item:first-child .page-link {
  border-radius: 0;
}

.blog-pagination .page-link:hover {
  color: #fff;
  text-decoration: none;
  border-color: #eee;
}

/*============ Start Blog Single Styles  =============*/
.single-post-area .social-links {
  padding-top: 10px;
}
.single-post-area .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}
.single-post-area .social-links li a {
  color: #cccccc;
  padding: 7px;
  font-size: 14px;
  transition: all 0.2s linear;
}
.single-post-area .social-links li a:hover {
  color: #222222;
}
.single-post-area .blog_details {
  padding-top: 26px;
}
.single-post-area .blog_details p {
  margin-bottom: 10px;
}
.single-post-area .quotes {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 24px 35px 24px 30px;
  background-color: white;
  box-shadow: -20.84px 21.58px 30px 0px rgba(176, 176, 176, 0.1);
  font-size: 14px;
  line-height: 24px;
  color: #777;
  font-style: italic;
}
.single-post-area .arrow {
  position: absolute;
}
.single-post-area .arrow .lnr {
  font-size: 20px;
  font-weight: 600;
}
.single-post-area .thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.8);
}
.single-post-area .navigation-area {
  border-top: 1px solid #eee;
  padding-top: 30px;
  margin-top: 60px;
}
.single-post-area .navigation-area p {
  margin-bottom: 0px;
}
.single-post-area .navigation-area h4 {
  font-size: 18px;
  line-height: 25px;
  color: #222222;
}
.single-post-area .navigation-area .nav-left {
  text-align: left;
}
.single-post-area .navigation-area .nav-left .thumb {
  margin-right: 20px;
  background: #000;
}
.single-post-area .navigation-area .nav-left .thumb img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-left .lnr {
  margin-left: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-left:hover .lnr {
  opacity: 1;
}
.single-post-area .navigation-area .nav-left:hover .thumb img {
  opacity: 0.5;
}
@media (max-width: 767px) {
  .single-post-area .navigation-area .nav-left {
    margin-bottom: 30px;
  }
}
.single-post-area .navigation-area .nav-right {
  text-align: right;
}
.single-post-area .navigation-area .nav-right .thumb {
  margin-left: 20px;
  background: #000;
}
.single-post-area .navigation-area .nav-right .thumb img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-right .lnr {
  margin-right: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-right:hover .lnr {
  opacity: 1;
}
.single-post-area .navigation-area .nav-right:hover .thumb img {
  opacity: 0.5;
}
@media (max-width: 991px) {
  .single-post-area .sidebar-widgets {
    padding-bottom: 0px;
  }
}

.comments-area {
  border: 1px solid #eee;
  padding: 50px 30px;
  margin-top: 50px;
}
@media (max-width: 414px) {
  .comments-area {
    padding: 50px 8px;
  }
}
.comments-area h4 {
  text-align: center;
  margin-bottom: 50px;
  color: #222222;
  font-size: 18px;
}
.comments-area h5 {
  font-size: 16px;
  margin-bottom: 0px;
}
.comments-area a {
  color: #222222;
}
.comments-area .comment-list {
  padding-bottom: 48px;
}
.comments-area .comment-list:last-child {
  padding-bottom: 0px;
}
.comments-area .comment-list.left-padding {
  padding-left: 25px;
}
@media (max-width: 413px) {
  .comments-area .comment-list .single-comment h5 {
    font-size: 12px;
  }
  .comments-area .comment-list .single-comment .date {
    font-size: 11px;
  }
  .comments-area .comment-list .single-comment .comment {
    font-size: 10px;
  }
}
.comments-area .thumb {
  margin-right: 20px;
}
.comments-area .date {
  font-size: 13px;
  color: #cccccc;
  margin-bottom: 13px;
}
.comments-area .comment {
  color: #777777;
  margin-bottom: 0px;
}
.comments-area .btn-reply {
  background-color: #fff;
  color: #222222;
  border: 1px solid #eee;
  padding: 2px 18px;
  font-size: 12px;
  display: block;
  font-weight: 600;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.comments-area .btn-reply:hover {
  background-color: #d12e43;
  color: #fff;
}

.comment-form {
  text-align: center;
  border: 1px solid #eee;
  padding: 47px 30px 43px;
  margin-top: 50px;
}
.comment-form h4 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 18px;
  line-height: 22px;
  color: #222222;
}
.comment-form .name {
  padding-left: 0px;
}
@media (max-width: 767px) {
  .comment-form .name {
    padding-right: 0px;
    margin-bottom: 1rem;
  }
}
.comment-form .email {
  padding-right: 0px;
}
@media (max-width: 991px) {
  .comment-form .email {
    padding-left: 0px;
  }
}
.comment-form .form-control {
  padding: 8px 20px;
  background: #fff;
  border: none;
  border-radius: 0px;
  width: 100%;
  font-size: 14px;
  color: #777777;
  border: 1px solid transparent;
}
.comment-form .form-control:focus {
  box-shadow: none;
  border: 1px solid #eee;
}
.comment-form textarea.form-control {
  height: 140px;
  resize: none;
}
.comment-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
  color: #777;
}
.comment-form ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
  color: #777;
}
.comment-form :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
  color: #777;
}
.comment-form :-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
  color: #777;
}

/*============ End Blog Single Styles  =============*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============== contact_area css ================*/
.mapBox {
  height: 420px;
  margin-bottom: 80px;
}

.contact_info .info_item {
  position: relative;
  padding-left: 45px;
}
.contact_info .info_item i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  line-height: 24px;
  color: #d12e43;
  font-weight: 600;
}
.contact_info .info_item h6 {
  font-size: 16px;
  line-height: 24px;
  color: "Raleway", sans-serif;
  font-weight: bold;
  margin-bottom: 0px;
  color: #222222;
}
.contact_info .info_item h6 a {
  color: #222222;
}
.contact_info .info_item p {
  font-size: 14px;
  line-height: 24px;
  padding: 2px 0px;
}

.contact_form .form-group {
  margin-bottom: 10px;
}
.contact_form .form-group .form-control {
  font-size: 13px;
  line-height: 26px;
  color: #999;
  border: 1px solid #eeeeee;
  font-family: "Raleway", sans-serif;
  border-radius: 0px;
  padding-left: 20px;
}
.contact_form .form-group .form-control:focus {
  box-shadow: none;
  outline: none;
}
.contact_form .form-group .form-control.placeholder {
  color: #999;
}
.contact_form .form-group .form-control:-moz-placeholder {
  color: #999;
}
.contact_form .form-group .form-control::-moz-placeholder {
  color: #999;
}
.contact_form .form-group .form-control::-webkit-input-placeholder {
  color: #999;
}
.contact_form .form-group textarea {
  resize: none;
}
.contact_form .form-group textarea.form-control {
  height: 134px;
}
.contact_form .primary-btn {
  margin-top: 20px;
  border: 0;
  border-radius: 50px;
  color: #fff;
}
.contact_form .primary-btn:after {
  border-radius: 50px;
}
.contact_form .primary-btn:hover {
  background: #fff;
  color: #222222;
}
.contact_form .primary-btn:hover:after {
  background: #f4f8ff;
  border: 1px solid #eeeeee;
}

/* Contact Success and error Area css
============================================================================================ */
.modal-message .modal-dialog {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) !important;
  margin: 0px;
  max-width: 500px;
  width: 100%;
}
.modal-message .modal-dialog .modal-content .modal-header {
  text-align: center;
  display: block;
  border-bottom: none;
  padding-top: 50px;
  padding-bottom: 50px;
}
.modal-message .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  right: -15px;
  top: -15px;
  padding: 0px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
}
.modal-message .modal-dialog .modal-content .modal-header h2 {
  display: block;
  text-align: center;
  color: #d12e43;
  padding-bottom: 10px;
  font-family: "Raleway", sans-serif;
}
.modal-message .modal-dialog .modal-content .modal-header p {
  display: block;
}

/* End Contact Success and error Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============== Elements Area css ================*/
.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.sample-text-area {
  padding: 100px 0px;
}
.sample-text-area .title_color {
  margin-bottom: 30px;
}
.sample-text-area p {
  line-height: 26px;
}
.sample-text-area p b {
  font-weight: bold;
  color: #d12e43;
}
.sample-text-area p i {
  color: #d12e43;
  font-style: italic;
}
.sample-text-area p sup {
  color: #d12e43;
  font-style: italic;
}
.sample-text-area p sub {
  color: #d12e43;
  font-style: italic;
}
.sample-text-area p del {
  color: #d12e43;
}
.sample-text-area p u {
  color: #d12e43;
}

/*============== End Elements Area css ================*/
/*==============Elements Button Area css ================*/
.elements_button .title_color {
  margin-bottom: 30px;
  color: #222222;
}

.title_color {
  color: #222222;
}

.button-group-area {
  margin-top: 15px;
}
.button-group-area:nth-child(odd) {
  margin-top: 40px;
}
.button-group-area:first-child {
  margin-top: 0px;
}
.button-group-area .theme_btn {
  margin-right: 10px;
}
.button-group-area .link {
  text-decoration: underline;
  color: #222222;
  background: transparent;
}
.button-group-area .link:hover {
  color: #fff;
}
.button-group-area .disable {
  background: transparent;
  color: #007bff;
  cursor: not-allowed;
}
.button-group-area .disable:before {
  display: none;
}

.primary {
  background: #52c5fd;
}
.primary:before {
  background: #2faae6;
}

.success {
  background: #4cd3e3;
}
.success:before {
  background: #2ebccd;
}

.info {
  color: #38a4ff;
}
.info:before {
  background: #298cdf;
}

.warning {
  background: #f4e700;
}
.warning:before {
  background: #e1d608;
}

.danger {
  background: #f54940;
}
.danger:before {
  background: #e13b33;
}

.primary-border {
  background: transparent;
  border: 1px solid #52c5fd;
  color: #52c5fd;
}
.primary-border:before {
  background: #52c5fd;
}

.success-border {
  background: transparent;
  border: 1px solid #4cd3e3;
  color: #4cd3e3;
}
.success-border:before {
  background: #4cd3e3;
}

.info-border {
  background: transparent;
  border: 1px solid #38a4ff;
  color: #38a4ff;
}
.info-border:before {
  background: #38a4ff;
}

.warning-border {
  background: #fff;
  border: 1px solid #f4e700;
  color: #f4e700;
}
.warning-border:before {
  background: #f4e700;
}

.danger-border {
  background: transparent;
  border: 1px solid #f54940;
  color: #f54940;
}
.danger-border:before {
  background: #f54940;
}

.link-border {
  background: transparent;
  border: 1px solid #d12e43;
  color: #d12e43;
}
.link-border:before {
  background: #d12e43;
}

.radius {
  border-radius: 3px;
}

.circle {
  border-radius: 20px;
}

.arrow span {
  padding-left: 5px;
}

.e-large {
  line-height: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.large {
  line-height: 45px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.medium {
  line-height: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.small {
  line-height: 25px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.general {
  line-height: 38px;
  padding-top: 0px;
  padding-bottom: 0px;
}

/*==============End Elements Button Area css ================*/
/* =================================== */
/*  Elements Page Styles
/* =================================== */
/*---------- Start Elements Page -------------*/
.generic-banner {
  margin-top: 60px;
  text-align: center;
}

.generic-banner .height {
  height: 600px;
}

@media (max-width: 767.98px) {
  .generic-banner .height {
    height: 400px;
  }
}
.generic-banner .generic-banner-content h2 {
  line-height: 1.2em;
  margin-bottom: 20px;
}

@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content h2 br {
    display: none;
  }
}
.generic-banner .generic-banner-content p {
  text-align: center;
  font-size: 16px;
}

@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content p br {
    display: none;
  }
}
.generic-content h1 {
  font-weight: 600;
}

.about-generic-area {
  background: #fff;
}

.about-generic-area p {
  margin-bottom: 20px;
}

.white-bg {
  background: #fff;
}

.section-top-border {
  padding: 50px 0;
  border-top: 1px dotted #eee;
}

.switch-wrap {
  margin-bottom: 10px;
}

.switch-wrap p {
  margin: 0;
}

/*---------- End Elements Page -------------*/
.sample-text-area {
  padding: 100px 0 70px 0;
}

.sample-text {
  margin-bottom: 0;
}

.text-heading {
  margin-bottom: 30px;
  font-size: 24px;
}

.typo-list {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .typo-sec {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .element-wrap {
    margin-top: 30px;
  }
}
b,
sup,
sub,
u,
del {
  color: #f8b600;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
  color: #777777;
}

.button-area .border-top-generic {
  padding: 70px 15px;
  border-top: 1px dotted #eee;
}

.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px;
}

.button-group-area .genric-btn:last-child {
  margin-right: 0;
}

.circle {
  border-radius: 20px;
}

.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: 0.8em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.genric-btn:focus {
  outline: none;
}

.genric-btn.e-large {
  padding: 0 40px;
  line-height: 50px;
}

.genric-btn.large {
  line-height: 45px;
}

.genric-btn.medium {
  line-height: 30px;
}

.genric-btn.small {
  line-height: 25px;
}

.genric-btn.radius {
  border-radius: 3px;
}

.genric-btn.circle {
  border-radius: 20px;
}

.genric-btn.arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.genric-btn.arrow span {
  margin-left: 10px;
}

.genric-btn.default {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.default:hover {
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.default-border {
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.default-border:hover {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.primary {
  color: #fff;
  background: #f8b600;
  border: 1px solid transparent;
}

.genric-btn.primary:hover {
  color: #f8b600;
  border: 1px solid #f8b600;
  background: #fff;
}

.genric-btn.primary-border {
  color: #f8b600;
  border: 1px solid #f8b600;
  background: #fff;
}

.genric-btn.primary-border:hover {
  color: #fff;
  background: #f8b600;
  border: 1px solid transparent;
}

.genric-btn.success {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

.genric-btn.success:hover {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

.genric-btn.success-border {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

.genric-btn.success-border:hover {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

.genric-btn.info {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

.genric-btn.info:hover {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

.genric-btn.info-border {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

.genric-btn.info-border:hover {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

.genric-btn.warning {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

.genric-btn.warning:hover {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

.genric-btn.warning-border {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

.genric-btn.warning-border:hover {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

.genric-btn.danger {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

.genric-btn.danger:hover {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

.genric-btn.danger-border {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

.genric-btn.danger-border:hover {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

.genric-btn.link {
  color: #222222;
  background: #f9f9ff;
  text-decoration: underline;
  border: 1px solid transparent;
}

.genric-btn.link:hover {
  color: #222222;
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.link-border {
  color: #222222;
  border: 1px solid #f9f9ff;
  background: #fff;
  text-decoration: underline;
}

.genric-btn.link-border:hover {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.disable {
  color: #222222, 0.3;
  background: #f9f9ff;
  border: 1px solid transparent;
  cursor: not-allowed;
}

.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #fff;
  border-left: 2px solid #f8b600;
}

@media (max-width: 991px) {
  .progress-table-wrap {
    overflow-x: scroll;
  }
}
.progress-table {
  background: #fff;
  padding: 15px 0px 30px 0px;
  min-width: 800px;
}

.progress-table .serial {
  width: 11.83%;
  padding-left: 30px;
}

.progress-table .country {
  width: 28.07%;
}

.progress-table .visit {
  width: 19.74%;
}

.progress-table .percentage {
  width: 40.36%;
  padding-right: 50px;
}

.progress-table .table-head {
  display: flex;
}

.progress-table .table-head .serial,
.progress-table .table-head .country,
.progress-table .table-head .visit,
.progress-table .table-head .percentage {
  color: #222222;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500;
}

.progress-table .table-row {
  padding: 15px 0;
  border-top: 1px solid #edf3fd;
  display: flex;
}

.progress-table .table-row .serial,
.progress-table .table-row .country,
.progress-table .table-row .visit,
.progress-table .table-row .percentage {
  display: flex;
  align-items: center;
}

.progress-table .table-row .country img {
  margin-right: 15px;
}

.progress-table .table-row .percentage .progress {
  width: 80%;
  border-radius: 0px;
  background: transparent;
}

.progress-table .table-row .percentage .progress .progress-bar {
  height: 5px;
  line-height: 5px;
}

.progress-table .table-row .percentage .progress .progress-bar.color-1 {
  background-color: #6382e6;
}

.progress-table .table-row .percentage .progress .progress-bar.color-2 {
  background-color: #e66686;
}

.progress-table .table-row .percentage .progress .progress-bar.color-3 {
  background-color: #f09359;
}

.progress-table .table-row .percentage .progress .progress-bar.color-4 {
  background-color: #73fbaf;
}

.progress-table .table-row .percentage .progress .progress-bar.color-5 {
  background-color: #73fbaf;
}

.progress-table .table-row .percentage .progress .progress-bar.color-6 {
  background-color: #6382e6;
}

.progress-table .table-row .percentage .progress .progress-bar.color-7 {
  background-color: #a367e7;
}

.progress-table .table-row .percentage .progress .progress-bar.color-8 {
  background-color: #e66686;
}

.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-gallery-image:hover {
  opacity: 0.8;
}

.list-style {
  width: 14px;
  height: 14px;
}

.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important;
  list-style: none;
}

.unordered-list li:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid #f8b600;
  background: #fff;
  top: 4px;
  left: 0;
  border-radius: 50%;
}

.ordered-list {
  margin-left: 30px;
}

.ordered-list li {
  list-style-type: decimal-leading-zero;
  color: #f8b600;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list li span {
  font-weight: 300;
  color: #777777;
}

.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #f8b600;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list-alpha li span {
  font-weight: 300;
  color: #777777;
}

.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #f8b600;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list-roman li span {
  font-weight: 300;
  color: #777777;
}

.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input:focus {
  outline: none;
}

.input-group-icon {
  position: relative;
}

.input-group-icon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  line-height: 40px;
  z-index: 3;
}

.input-group-icon .icon i {
  color: #797979;
}

.input-group-icon .single-input {
  padding-left: 45px;
}

.single-textarea {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
  height: 100px;
  resize: none;
}

.single-textarea:focus {
  outline: none;
}

.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input-primary:focus {
  outline: none;
  border: 1px solid #f8b600;
}

.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input-accent:focus {
  outline: none;
  border: 1px solid #eb6b55;
}

.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input-secondary:focus {
  outline: none;
  border: 1px solid #f09359;
}

.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.default-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.default-switch input + label {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #f8b600;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.default-switch input:checked + label {
  left: 19px;
}

.single-element-widget {
  margin-bottom: 30px;
}

.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.primary-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.primary-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.primary-switch input:checked + label:after {
  left: 19px;
}

.primary-switch input:checked + label:before {
  background: #f8b600;
}

.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.confirm-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.confirm-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

.confirm-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.confirm-switch input:checked + label:after {
  left: 19px;
}

.confirm-switch input:checked + label:before {
  background: #4cd3e3;
}

.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.primary-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.single-defination h4 {
  color: #222222;
}

.primary-checkbox input:checked + label {
  background: url(../images/elements/primary-check.png) no-repeat center
    center/cover;
  border: none;
}

.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.confirm-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.confirm-checkbox input:checked + label {
  background: url(../images/elements/success-check.png) no-repeat center
    center/cover;
  border: none;
}

.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.disabled-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.disabled-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.disabled-checkbox input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

.disabled-checkbox input:checked + label {
  background: url(../images/elements/disabled-check.png) no-repeat center
    center/cover;
  border: none;
}

.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.primary-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.primary-radio input:checked + label {
  background: url(../images/elements/primary-radio.png) no-repeat center
    center/cover;
  border: none;
}

.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.confirm-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.confirm-radio input:checked + label {
  background: url(../images/elements/success-radio.png) no-repeat center
    center/cover;
  border: none;
}

.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.disabled-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.disabled-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.disabled-radio input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

.unordered-list {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.ordered-list {
  list-style: none;
  padding: 0px;
}

.disabled-radio input:checked + label {
  background: url(../images/elements/disabled-radio.png) no-repeat center
    center/cover;
  border: none;
}

.default-select {
  height: 40px;
}

.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #fff;
  padding-left: 20px;
  padding-right: 40px;
}

.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

.default-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px;
}

.default-select .nice-select .list .option.selected {
  color: #f8b600;
  background: transparent;
}

.default-select .nice-select .list .option:hover {
  color: #f8b600;
  background: transparent;
}

.default-select .current {
  margin-right: 50px;
  font-weight: 300;
}

.default-select .nice-select::after {
  right: 20px;
}

@media (max-width: 991px) {
  .left-align-p p {
    margin-top: 20px;
  }
}
.form-select {
  height: 40px;
  width: 100%;
}

.form-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff !important;
  padding-left: 45px;
  padding-right: 40px;
  width: 100%;
}

.form-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

.mt-10 {
  margin-top: 10px;
}

.form-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 45px;
}

.form-select .nice-select .list .option.selected {
  color: #f8b600;
  background: transparent;
}

.form-select .nice-select .list .option:hover {
  color: #f8b600;
  background: transparent;
}

.form-select .current {
  margin-right: 50px;
  font-weight: 300;
}

.form-select .nice-select::after {
  right: 20px;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Main Button Area css
============================================================================================ */
.white_bg_btn {
  background: #fff;
  display: inline-block;
  color: #222222;
  line-height: 40px;
  padding: 0px 28px;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border: none;
}
.white_bg_btn:hover {
  background: #d12e43;
  color: #fff;
}

.blog_btn {
  border: 1px solid #eeeeee;
  background: #f9f9ff;
  padding: 0px 32px;
  font-size: 13px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  color: #222222;
  line-height: 34px;
  display: inline-block;
}
.blog_btn:hover {
  background: #d12e43;
  border-color: #d12e43;
  color: #fff;
}

/* End Main Button Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============== footer-area css ===============*/
.footer-area {
  background-color: #222222;
}
.footer-area h5 {
  color: #fff;
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 700;
}
.footer-area .footer_top {
  padding-bottom: 80px;
}
@media (max-width: 991px) {
  .footer-area .footer_top {
    padding-bottom: 40px;
  }
}

.instafeed {
  margin: 0px;
  padding: 0;
  list-style: none;
}
.instafeed li {
  overflow: hidden;
  width: 25%;
}
.instafeed li img {
  margin: 5px;
}

@media (max-width: 991px) {
  .single-footer-widget {
    margin-bottom: 40px;
  }
}
.single-footer-widget .footer-newsletter {
  margin-top: 30px;
}
.single-footer-widget .form-inline {
  display: inline-block;
  width: 100%;
}
.single-footer-widget p {
  color: #999999;
  margin-bottom: 0;
}
.single-footer-widget input {
  border: none;
  width: 80% !important;
  font-weight: 300;
  background: #191919;
  color: #777;
  padding-left: 20px;
  border-radius: 0;
  font-size: 14px;
}
.single-footer-widget input:focus {
  background-color: #191919;
  outline: none;
  box-shadow: none;
}
.single-footer-widget .list li a {
  line-height: 25px;
  display: inline-block;
  color: #999999;
  transition: all 0.3s linear;
  margin-bottom: 13px;
  font-size: 14px;
}
.single-footer-widget .list li a:hover {
  color: #d12e43;
}
.single-footer-widget .bb-btn {
  color: #fff;
  font-weight: 300;
  border-radius: 0;
  z-index: 9999;
  cursor: pointer;
}
.single-footer-widget .info {
  position: absolute;
  margin-top: 20%;
  color: #fff;
  font-size: 12px;
}
.single-footer-widget .info.valid {
  color: green;
}
.single-footer-widget .info.error {
  color: red;
}
.single-footer-widget .click-btn {
  color: #fff;
  border-radius: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 5px 12px;
  border: 0;
}
.single-footer-widget .click-btn:focus {
  outline: none;
  box-shadow: none;
}
.single-footer-widget ::-moz-selection {
  /* Code for Firefox */
  background-color: #191919 !important;
  color: #777777;
}
.single-footer-widget ::selection {
  background-color: #191919 !important;
  color: #777777;
}
.single-footer-widget ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #777777;
  font-weight: 300;
}
.single-footer-widget :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #777777;
  opacity: 1;
  font-weight: 300;
}
.single-footer-widget ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #777777;
  opacity: 1;
  font-weight: 300;
}
.single-footer-widget :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #777777;
  font-weight: 300;
}
.single-footer-widget ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #777777;
  font-weight: 300;
}

.footer-text {
  padding-top: 80px;
}
.footer-text a,
.footer-text i {
  color: #d12e43;
}

.copyright {
  background: #222222;
  padding: 18px 0;
  border-top: 1px solid #333333;
}
.copyright p {
  margin: 0;
  color: #999999;
}
@media (max-width: 991px) {
  .copyright p {
    text-align: center;
  }
}
@media (max-width: 991px) {
  .copyright .social-icons {
    text-align: center;
    margin-top: 20px;
  }
}
.copyright .social-icons a {
  padding-right: 15px;
}
.copyright .social-icons a i {
  color: #999999;
}
.copyright .social-icons a i:hover {
  color: #d12e43;
}

#about .footer-area {
  margin-top: 120px;
}
@media (max-width: 991px) {
  #about .footer-area {
    margin-top: 80px;
  }
}

/*---------------------------------------------------- */

/*# sourceMappingURL=style.css.map */
