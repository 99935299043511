.home_banner_area .banner_inner .banner_content h2.banner-area-primary {
  color: #d12e43;
  font-size: 60px;
}

.home_banner_area .banner_inner .banner_content p.banner-title {
  font-size: 30px;
}

#section-banner h2 {
  overflow-wrap: anywhere;
}
