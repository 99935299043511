.single_feature.single_feature_hover {
  background: linear-gradient(90deg, #d12e43 0%, #f48464 100%);
}

.single_feature.single_feature_hover .info-content p {
  color: white;
}

#features_counter .counter_wrapper {
  row-gap: 10px;
}
