.video-right.video-right-summary {
  padding-left: 15px;
  margin-top: 0;
}

.video-right.video-right-summary .right-bottom-area {
  margin-top: 5%;
  background-color: black;
}

.video-right.video-right-summary .author-lacture {
  color: white;
  font-weight: bold;
}

#about .video-right {
  margin-top: 0;
}
